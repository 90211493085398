import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, customElement, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@customElement('summary-common-component')
@inject(Element, Backend, ShoppingCart, Router)
export class SummaryCommonComponent {
  element;
  backend;
  shoppingCart;
  router;

  shoppingCartInfo;
  calculatedCart;
  totalIncVat;
  @bindable() showInfo = false;

  constructor(element, backend, shoppingCart, router) {
    this.element = element;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.router = router;
  }

  listener;
  attached() {
    this.listener = shoppingCartInfo => {
      this.shoppingCartInfo = shoppingCartInfo;
      this.refresh();
    };
    this.shoppingCart.addListener(this.listener);
  }

  detached() {
    if (this.listener)
        this.shoppingCart.removeListener(this.listener);
  }

  refresh() {
    this.shoppingCart.dryRunCheckout()
      .then(result => {
        this.calculatedCart = result.lines;
        this.totalIncVat = result.totalIncVat;
      });
  }

  clearCart() {
    this.shoppingCart.clearCart();
    this.router.navigateToRoute('crate-catalog')
  }
}
