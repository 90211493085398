import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject} from 'aurelia-framework';
import {Redirect, Router} from 'aurelia-router';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin'
import {NotificationService} from "../../util/notification-service";
import {UserInfoProvider} from "../../util/user-info-provider";
import {RMatomo} from "../../util/r-matomo";
import isMobile from "ismobilejs";

@inject(Backend, ShoppingCart, Router, IdentityProvider, NotificationService, UserInfoProvider)
export class CheckoutFlowScreen {
  backend;
  shoppingCart;
  router;
  step;
  identityProvider;
  customerId;
  params;
  notificationService;
  userInfoProvider;

  constructor(backend, shoppingCart, router, identityProvider, notificationService, userInfoProvider) {
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.identityProvider = identityProvider;
    this.notificationService = notificationService;
    this.userInfoProvider = userInfoProvider;
    this.isMobile = isMobile().phone;
  }

  canActivate() {
    // We currently don't allow purchase of multiple subscriptions.
    let info = this.userInfoProvider.getInfo();
    if (info.hasSubscription) {
      this.notificationService.showOnNextRouterSuccess('Du har allerede et abonnement og blir nå flyttet til "min kasse". Hvis du vil bytte produkt trykk "endre kassetype".')
      return new Redirect('my-page');
    }
  }

  static SUMMARY_OR_INTERVAL_AND_SUMMARY = 'summary-or-interval-and-summary';

  activate(params) {
    this.params = params;
    return this.shoppingCart.load()
      .then(() => {
        //// Decide if we should set the crate product
        let forceCrateProductIdPromise;
        // If referrer specified crate product, use that.
        if (params.crateProductId) {
          forceCrateProductIdPromise = Promise.resolve(params.crateProductId);
        }
        // If crate product has not been specified, use default crate.
        else if (!this.shoppingCart.hasCrateProduct()) {
          let defaultCrateProductId = this.shoppingCart.getDefaultCrateProductId();
          forceCrateProductIdPromise = defaultCrateProductId;
        }
        // Else: don't change the crate product. Leave shopping cart as is.
        else {
          forceCrateProductIdPromise = Promise.resolve(null);
        }

        return forceCrateProductIdPromise.then(forceCrateProductId => {
          if (forceCrateProductId) {
            return this.shoppingCart.setCrateProductId(forceCrateProductId)
              .then(() => {
                // Go to first step
                this.goToFirstStep();

                // Remove query parameters
                this.router.navigateToRoute('checkout-flow')
              });
          } else if (params.step) {
            // We have instructions to go to a specific step
            if (params.step === 'first') {
              this.goToFirstStep();
            } else if (params.step === CheckoutFlowScreen.SUMMARY_OR_INTERVAL_AND_SUMMARY) {
              this.goToSummaryStep();
            } else {
              this.goToStep(params.step);
            }
            // Remove query parameters
            this.router.navigateToRoute('checkout-flow')
          } else {
            // Decide which step to go to
            if (this.shoppingCart.getInfo().step) {
              // Load existing step
              let step = this.shoppingCart.getInfo().step;
              console.log("step loading: " + this.step);
              this.goToStep(step);
            } else {
              // Go to "step zero"
              this.router.navigateToRoute('crate-catalog');
            }
          }
        });
      });
  }

  goToSummaryStep() {
    if (this.isMobile) {
      this.goToStep('summary');
    } else {
      this.goToStep('interval-and-summary');
    }
  }
  goToFirstStep() {
    // First step is sub products
    if (this.shoppingCart.isCustomizable()) {
      this.goToStep('customizable-content');
    } else {
      if (this.isMobile) {
        this.goToStep('summary');
      } else {
        this.goToStep('interval-and-summary');
      }
    }
  }

  catalogComponentPurchase(eventDetail) {
    this.shoppingCart.setCrateProduct(
      eventDetail.productId,
      eventDetail.name,
      eventDetail.quantity,
      false,
      [],
      eventDetail.isCustomizable);
    this.next();
  }

  login(eventDetail) {
    console.log('Is logged in. Skipping to next step');
    this.next();
  }

  intervalSubmit(eventDetail) {
    this.shoppingCart.setInterval(
      eventDetail.weekInterval,
      eventDetail.startWeek,
      eventDetail.weekDay,
      eventDetail.commentForPacking,
      eventDetail.commentForDriver);
  }

  checkoutComplete(eventDetail) {
    // Reset flow
    this.step = null;

    // Show receipt
    this.router.navigateToRoute('receipt');
  }

  next() {
    switch (this.step) {
      case 'week-extra':
        if (this.isMobile) {
          this.goToStep('summary');
        } else {
          this.goToStep('interval-and-summary');
        }
        break;
      case 'customizable-content':
        if (this.isMobile) {
          this.goToStep('summary');
        } else {
          this.goToStep('interval-and-summary');
        }
        break;
      case 'summary':
        this.goToStep('interval');
        break;
      case 'interval':
        this.goToStep('login');
        break;
      case 'interval-and-summary':
        this.goToStep('login');
        break;
      case 'login':
        this.goToStep('address');
        break;
      case 'address':
        this.goToStep('payment');
        break;
      case 'payment':
        this.goToStep('receipt');
        break;
      default:
        this.shoppingCart.clearCart()
        this.router.navigateToRoute('crate-catalog')
    }
  }

  back() {
    switch (this.step) {
      case 'week-extra':
        this.router.navigateToRoute('crate-catalog')
        break;
      case 'customizable-content':
        this.router.navigateToRoute('crate-catalog')
        break;
      case 'summary':
        if (this.shoppingCart.isCustomizable()) {
          this.goToStep('customizable-content');
        } else {
          this.router.navigateToRoute('crate-catalog');
        }
        break;
      case 'interval':
        this.goToStep('summary');
        break;
      case 'interval-and-summary':
        if (this.shoppingCart.isCustomizable()) {
          this.goToStep('customizable-content');
        } else {
          this.router.navigateToRoute('crate-catalog');
        }
        break;
      case 'login':
        if (this.isMobile) {
          this.goToStep('interval');
        } else {
          this.goToStep('interval-and-summary');
        }
        break;
      case 'address':
        if (this.identityProvider.isCurrentlyLoggedIn()) {
          if (this.isMobile) {
            this.goToStep('interval');
          } else {
            this.goToStep('interval-and-summary');
          }
        } else {
          this.goToStep('login');
        }
        break;
      case 'payment':
        this.goToStep('address');
        break;
      default:
        this.shoppingCart.clearCart()
    }
  }

  goToStep(nextStep) {
    // Some steps are inaccessible for non logged in users.
    let isLoggedIn = this.identityProvider.isCurrentlyLoggedIn();
    let mustBeLoggedIn = this.isAuthOnly(nextStep);
    if (mustBeLoggedIn && !isLoggedIn) {
      this.goToStep('login');
      return;
    }

    // Save
    this.shoppingCart.saveStep(nextStep);

    // Go to step
    this.step = nextStep;

    // Track
    RMatomo.trackPageView(window.location.toString() + '/' + nextStep, 'Checkout flow - ' + nextStep);
  }

  isAuthOnly(step) {
    let authOnly = [
      'address',
      'payment'
    ];
    let nonAuthOk = [
      'week-extra',
      'customizable-content',
      'interval',
      'summary',
      'interval-and-summary',
      'login',
    ];
    if (authOnly.includes(step)) {
      return true;
    } else if (nonAuthOk.includes(step)) {
      return false;
    } else {
      // If we get into this situation, it might be that a customer is stuck in a nonexisting step because of old stuff in local storage.
      // We are failing anyway, losing the cart content isn't going to be that much worse, and it'll safeguard against unfixable states.
      this.shoppingCart.clearCart()
      throw new Error(`Step ${step} was not part of authOnly list NOR nonAuthOk list.`);
    }
  }

  clearCart() {
    this.shoppingCart.clearCart();
    this.router.navigateToRoute('crate-catalog')
  }
}
