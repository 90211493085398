import Backend from 'backend';
import {inject, customElement, bindable, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@customElement('my-page-subscription-lines-component')
@inject(Element, Backend, Router)
export class MyPageSubscriptionLinesComponent {
  element;
  backend;
  router;

  @bindable
  subscription;

  confirmationModal;
  notificationModal;
  quantityChangeInProgress = false;

  constructor(element, backend, router) {
    this.element = element;
    this.backend = backend;
    this.router = router;
  }

  showWeekExtraButton(subscription) {
    let filter = subscription.lines.filter(line => line.isCustomizable);
    return filter.length === 0;
  }

  hasVisibleSubLines(line) {
    return line.subLines.filter(subLine => !subLine.syntheticLine || line.isCustomizable).length > 0
  }

  quantityChanged(subscription, line, subLine, event) {
    let isSubLine = subLine !== null;

    // Can not change subLine unless contained in a crate
    if (isSubLine && !line.isCrate) {
      return;
    }

    let needToConvertToCustomizable = isSubLine && !line.isCustomizable;
    if (needToConvertToCustomizable) {
      // The content of regular bundle crates cannot really be changed, but we show increment and decrement buttons anyway.
      // If the user clicks them we'll have to first replace the bundle crate with a customizable crate

      this.confirmationModal.open()
        .then(ok => {
          // If the user didn't click OK in the confirmation modal, don't continue
          if (!ok) {
            return;
          }

          this.quantityChangeInProgress = true;
          return this.backend.ConvertSubscriptionLineToCustomizableCommandHandler_handle({
              subscriptionId: subscription.subscriptionId,
              subscriptionProductId: line.subscriptionProductId,
              yearWeek: subscription.yearWeekOfProjectedLines,
              productIdsToIncrement: [{
                productId: subLine.productId,
                increment: event.detail.increment}]
            })
            // After converting, show another modal
            .then(() => this.notificationModal.open())
            // After closing that modal refresh my-page
            // Don't do this before showing the modal, because the modal will disappear when my-page is refreshed.
            .then(() => this.refreshNeeded())
            // NOTE: quantityChangeInProgress is not normally set to false because it will then show the + and - buttons in the short time before the page is
            // actually refreshed. And when the page is refreshed the progress indicator will be reset anyway. But in case of errors, refresh won't happen.
            .catch(error => {
              this.quantityChangeInProgress = false;
              throw error;
            })
          });
    } else {
      this.quantityChangeInProgress = true;
      this.backend.IncrementSubscriptionLineCommandHandler_handle({
          subscriptionId: subscription.subscriptionId,
          subscriptionLineId: line.subscriptionProductId,
          subscriptionSubLineId: subLine?.subscriptionProductId,
          quantityIncrement: event.detail.increment
        })
        .then(() => this.refreshNeeded())
        // NOTE: quantityChangeInProgress is not normally set to false because it will then show the + and - buttons in the short time before the page is
        // actually refreshed. And when the page is refreshed the progress indicator will be reset anyway. But in case of errors, refresh won't happen.
        .catch(error => {
          this.quantityChangeInProgress = false;
          throw error;
        })
    }
  }

  refreshNeeded() {
    this.element.dispatchEvent(new CustomEvent('refresh-needed', {
      detail: null,
      bubbles: true
    }));
  }
}
