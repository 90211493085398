import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, customElement, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@customElement('interval-and-summary-component')
@inject(Element, Backend, ShoppingCart, Router)
export class IntervalAndSummaryComponent {
  backend;
  shoppingCart;
  router;

  // View models
  weekExtraComponent;
  customizableContentComponent;
  intervalCommonComponent;

  constructor(element, backend, shoppingCart, router) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
  }

  submit() {
    // When going to next step we should also let the week extra component do it's 'next' operation.
    if (this.weekExtraComponent) {
      this.weekExtraComponent.addChosenProductsToCart();
    } else if (this.customizableContentComponent) {
      this.customizableContentComponent.addChosenProductsToCart();
    }

    // Post interval form back to checkout-flow
    this.intervalCommonComponent.submit();

    // Next step
    this.element.dispatchEvent(new CustomEvent('checkout-flow-next', {
      detail: {},
      bubbles: true
    }))
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}
