import {inject, bindable, customElement} from 'aurelia-framework';
import Backend from "../backend";
import {ShoppingCart} from "./checkout-flow/shopping-cart";
import {Router} from "aurelia-router";
import {CheckoutFlowScreen} from "./checkout-flow/checkout-flow-screen";

@customElement('crate-choice-modal-component')
@inject(Element, Backend, ShoppingCart, Router)
export class CrateChoiceModalComponent {
  // DI
  element;
  backend;
  shoppingCart;
  router;

  // View models
  crateChoiceModalRef;

  // Data fields
  crateProducts
  crateProductsNonCustomizable;
  crateProductsCustomizable;

  constructor(element, backend, shoppingCart, router) {
    this.element = element;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.router = router;
  }

  bind() {
    // Load crate product info
    this.backend.CrateCatalogQueryHandler_handle()
      .then(result => {
        this.crateProducts = result.items;

        // Split into customizable and not
        this.crateProductsNonCustomizable = result.items.filter(crate => !crate.isCustomizable);
        this.crateProductsCustomizable = result.items.filter(crate => crate.isCustomizable);
      });
  }

  /** @param chosenProducts after crate is chosen we will need to know which subproducts should be added to the crate. */
  show(chosenProducts) {
    this.chosenProducts = chosenProducts;
    this.crateChoiceModalRef.open();
  }

  chooseCrateButtonClicked(crateProductId) {
    // Set crate product
    this.shoppingCart.setCrateProductId(crateProductId)
      .then(() => {
        // Store chosen sub products in shopping cart
        this.chosenProducts
          // The SubProductComponent allows quantity zero if initValues was non-zero because it should be possible to remove items,
          // but we don't want anything to be removed because of what we are doing here.
          .filter(product => product.quantity > 0)
          .forEach(product => this.shoppingCart.addSubProduct(product.productId, product.quantity));
      })
      // Go to checkout flow
      .then(() => this.router.navigateToRoute('checkout-flow', {step: CheckoutFlowScreen.SUMMARY_OR_INTERVAL_AND_SUMMARY}));
  }
}
