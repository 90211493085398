import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, customElement, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@customElement('interval-component')
@inject(Element, Backend, ShoppingCart, Router)
export class IntervalComponent {
  backend;
  shoppingCart;
  router;

  // View models
  intervalCommonComponent;

  constructor(element, backend, shoppingCart, router) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
  }

  attached() {
    this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__interval__section1"},
        ]})
      .then(result => {
        this.cmsFragments = result;
      })
  }

  submit() {
    // Post interval form back to checkout-flow
    this.intervalCommonComponent.submit();

    // Next step
    this.element.dispatchEvent(new CustomEvent('checkout-flow-next', {
      detail: {},
      bubbles: true
    }))
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}
