import {inject} from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import 'whatwg-fetch';
import {Configurations} from 'devtag-aurelia-config-plugin'

@inject(HttpClient, Configurations)
export class ApiClient {
  apiRoot;
  logUrl;
  accessToken;
  decorator;

  constructor(httpClient, config) {
    this.apiRoot = config.getConfig('apiRoot');
    this.logUrl = config.getConfig('logUrl');
    this.httpClient = httpClient;
    this.configure();
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
    this.configure();
  }

  clearAccessToken() {
    this.setAccessToken(null);
    this.configure();
  }

  configure() {
    this.httpClient.configure(config => {
      // config.useStandardConfiguration();
      config.withBaseUrl(this.apiRoot);
      config.rejectErrorResponses();
      // Note: when logging out without doing a page load (such as during failed silent renew).
      // The config object already has the old token on it so it is important to clear it.
      let headers = this.accessToken != null
        ? {'Authorization': 'Bearer ' + this.accessToken}
        : {};
      config.withDefaults({headers: headers});
    })
  }

  setDecorator(decorator) {
    if (typeof decorator !== 'function') {
      throw new Error('Decorator must be a function.');
    }
    this.decorator = decorator;
  }

  frontendLog(logData) {
    fetch(this.logUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        logData: logData,
        locationHref: window.location.href
      })
    })
  }

  invoke(className, methodName, args) {
    let promise = this.httpClient
      .fetch('/' + className + "/" + methodName,
        {
          method: 'POST',
          body: json(args)
        })

      // Error responses
      .catch(error => {
        if (error instanceof Error) {
          let message;
          if (error.message === 'Failed to fetch') {
            message = "Kunne ikke koble til server."
          } else {
            message = error.message;
          }
          throw new ApiError(message, false);
        }
        // If status code is 400 (bad request)
        else if (error.status === 400) {
          throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
        }
        // If status code is 401 (unauthorized)
        else if (error.status === 401) {
          throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
        }
        // If status code is 403 (forbidden)
        else if (error.status === 403) {
          throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
        }
        // If status code is 500 (Internal server error)
        else if (error.status === 500) {
          // Deserialize anyway, because it's a serialized exception from
          // the back end, wrap it in a standardized error type and throw it.
          return error
            .json()
            .then(json => new ApiError(
              json.message,
              true,
              json.throwable,
              json.throwableClassName,
              json.throwableClassSimpleName
            ))
            .then(error => {
              // Note: Even though we can return a promise from a promise handler
              // and expect it to be resolved before the next then() is called,
              // we can't _throw_ a promise and expect the same.
              // See https://stackoverflow.com/a/45890388/5377597
              throw error;
            });
        }
          throw new ApiError("ApiClient has no handling for error status code: " + error.status + " from url: " + error.url, false);
      })

      // Http status 2xx
      .then(response => {
        // 200 OK => response body is json.
          if (response.status === 200) {
          // Deserialize and return the payload. json() returns a promise,
          // but returning a promise from a promise handler works fine.
          return response.json();
        }
        // 204 NO_CONTENT => response body is empty because return type is void.
          else if (response.status === 204) {
          return;
        }

        // Should never get here.
        throw new Error("Only status 200 and 204 are supported OK responses, so this isn't expected.")
      });

    if (this.decorator === undefined) {
      return promise;
    } else {
      return this.decorator(promise);
    }
  }
}

export class ApiError extends Error {
  isExceptionFromBackend;
  exception;
  exceptionFullClassName;
  message2; // because the super class' message field doesn't seem to be serialized and displayed in some of our error handling.
  exceptionName;

  constructor(message, isExceptionFromBackend, exception, exceptionFullClassName, exceptionName) {
    super(message);
    this.name = this.constructor.name;
    this.message2 = message;
    this.isExceptionFromBackend = isExceptionFromBackend;
    this.exception = exception;
    this.exceptionFullClassName = exceptionFullClassName;
    this.exceptionName = exceptionName;
  }

  toString() {
    if (this.isExceptionFromBackend) {
      return "Exception from backend: "
        + this.exceptionFullClassName + "\n"
          + this.message;
    } else {
      return this.message;
    }
  }
}
